import { Component, OnInit } from '@angular/core';
import { HeadService } from 'src/app/services/head.service';

@Component({
  selector: 'app-taxlogin',
  templateUrl: './taxlogin.component.html',
  styleUrls: ['./taxlogin.component.scss'],
  standalone:false
})
export class TaxloginComponent implements OnInit {

  constructor(private head:HeadService) {

    this.head.setTitle("Taxes Client Login Portal");
    this.head.setDescription("IFA Taxes Charitable Tax Savings Calculator helps you determine your potential tax savings with a tax deductable gift to charity")
    this.head.setKeywords("Login, Taxes");


   }

  ngOnInit(): void {
  }

}
