import { DataServiceService } from './../../../services/data/data-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-taxabout',
  templateUrl: './taxabout.component.html',
  styleUrls: ['./taxabout.component.scss'],
  standalone:false
})
export class TaxaboutComponent implements OnInit, OnDestroy {

  subscription

  taxteams: any;

  constructor(private service: DataServiceService) {
    this.subscription = this.service.getTaxTeam().subscribe(resp => {
      // console.log (resp);
      resp = resp.map( x => { 
        x.imageUrl?(x.imageUrl = x.imageUrl.replace("512x512", "329x461").replace(/.(png|jpg|jpeg)/, ".webp")):(null); 
        x.url = x.friendlyUrl?.replace("https://www.ifa.com", ""); 
        return x });
     this.taxteams = resp;
     
   })
   }

  
   ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
  }

}
